import React, { useContext, useMemo } from "react";

import loadable from "@loadable/component";
import { useTranslation } from "react-i18next";

import ConfigContext from "@/components/Config/configContext";

const SessionForm = loadable(() => import("@/components/Session/SessionForm"));

export default function CartLogin() {
  const { t } = useTranslation();
  const { configQuery } = useContext(ConfigContext);

  const uiDefinitions = configQuery?.config?.uiDefinitions || [];
  const loginPromptDefinitions = uiDefinitions.filter(
    (definition) => definition.type == "login_prompt",
  );

  const pointProgram = configQuery.config?.pointProgram;

  const loginHeader = useMemo(() => {
    if (pointProgram?.loginHeader) {
      return pointProgram?.loginHeader;
    }
    if (loginPromptDefinitions.length > 0) {
      return loginPromptDefinitions
        .map((def) => def.definition.title)
        .join(", ");
    }
    return t("login.subheader");
  }, [pointProgram?.loginHeader, loginPromptDefinitions, t]);
  const loginDescription = useMemo(() => {
    if (pointProgram?.loginDescription) {
      return pointProgram?.loginDescription;
    }
    if (loginPromptDefinitions.length > 0) {
      return loginPromptDefinitions
        .map((def) => def.definition.body)
        .join(", ");
    }
    return t("login.description");
  }, [pointProgram?.loginDescription, loginPromptDefinitions, t]);

  return (
    <div className="p-4 mb-3 cart-login bg-default2">
      <SessionForm
        isCompact
        loginButtonType="primary-outline"
        validateOnBlur={false}
        loginOnly
      >
        <div className="mb-1">
          <strong
            dangerouslySetInnerHTML={{
              __html: loginHeader,
            }}
          />
        </div>
        <div className="mb-1">{loginDescription}</div>
      </SessionForm>
    </div>
  );
}

export default {
  app: "diner-app",
  common: {
    loading: "Loading...",
    unexpectedError: "An unexpected error occurred",
    confirm: "Confirm",
    form: {
      required: "Required",
    },
    quantity: "x{{quantity}}",
    orBreak: "or",
  },
  navbar: {
    faq: "FAQ",
    orderNow: "Order now",
    qrOutletLabel: "Ordering from",
    address: {
      delivery: "Select a delivery address",
      pickup: "Select a pickup location",
      default: "Select a delivery/pickup location",
    },
    pickupFrom: "Pick up from {{labelForPickup}}",
    timeslot: "Select date and time",
    signup: "Sign up",
    login: "Log in",
    logout: "Log out",
    addressToolTip: "Select a delivery address first",
    timeslotLabel: "Select a time slot",
    browseMenu: "Browse menu",
    tableName: "Table: {{ tableName }}",
  },
  block: {
    willOpenAtNextOpeningHours: {
      header: "Looks like you’re a little early",
      body: "Our store is currently closed and will open for orders at {{ openingTime }}",
    },
    outletCurrentlyClosed: {
      header: "Whoops, you’re a little too late",
      body: "Last orders for our store has ended. Please come back another day, thank you.",
    },
    closingCountdown:
      "This store is closing soon. Please place your orders by {{ closingTime }}",
  },
  scroll: {
    top: "TOP",
  },
  account: {
    header: "Account",
    navigation: {
      profile: "Profile",
      orders: "Orders",
      paymentMethods: "Payment Methods",
      addresses: "Addresses",
      referrals: "Referrals",
      promoCodes: "Promo codes",
      loyalty: "Loyalty program",
    },
    profile: {
      header: "Profile",
      subheader: "Powered by",
      description: "Manage your profile below",
      form: {
        labels: {
          title: "Title",
          name: "Name",
          email: "Email",
          mobileNumber: "Mobile number",
          currentPassword: "Current password",
          newPassword: "New password",
          newPasswordConfirmation: "New password confirmation",
        },
        update: "Update your profile",
        success: {
          update: "Profile updated",
        },
      },
      rewards: {
        header: "Rewards",
        subheader:
          "You currently have {{ count }} points in your rewards account.",
        profilePendingSync:
          "Your profile syncing with Advocado is in progress.",
        note: "Log in on <a class='underline text-link hover:text-default hover:underline' href='https://membership.ebbflowgroup.com/' target='_blank'>Advocado</a> to use your points and manage your account.",
        profileOutOfSync:
          "Your profile is now out of sync with Advocado. Please log in to <a class='underline text-link hover:text-default hover:underline' href='https://membership.ebbflowgroup.com/' target='_blank'>Advocado</a> to keep your rewards account updated.",
      },
    },
    orders: {
      header: "Orders",
      subheader:
        "Keep track of your upcoming and past orders all in one place.",
      upcomingOrders: "Upcoming orders",
      pastOrders: "Past orders",
      pagination:
        "Page {{ currentPage }} of {{ totalPages }} ({{ count }} order)",
      pagination_other:
        "Page {{ currentPage }} of {{ totalPages }} ({{ count }} orders)",
      pickupLabel: "Pick up at {{labelForPickup}}",
      dineInLabel: "Dine-in at {{labelForPickup}}",
      callNumberLabel:
        "We will call the number <span class='!font-bold'>{{callNumber}}</span> when order is ready for collection",
      fulfilledByLabel: "Fulfilled by {{outlet}}",
      orderCancelled: "Order cancelled",
      contactNumberLabel:
        "Contact: <a class='underline text-default hover:text-default hover:underline' href='tel:{{contactNumberNoSpaces}}'>{{contactNumber}}</a>",
      contactNumberLabelDelivery:
        "(<a class='underline text-default hover:text-default hover:underline' href='tel:{{contactNumberNoSpaces}}'>{{contactNumber}}</a>)",
      servingDateLabel: "Serving on {{ servingDate }}, {{ servingTime }}",
      servingDateCompletedLabel:
        "Served on {{ servingDate }}, {{ servingTime }}",
      servingDate: "{{ servingDate }}, {{ servingTime }}",
      invoiceDate: "Invoice date: {{ date }}",
      uen: "GST Reg No.: {{ uen }}",
      successfullyDelivered:
        "Your order was successfully delivered on {{ servingDate }}",
      identifier: "Order {{identifier}}",
      orderStatusHeader: "Order status",
      orderStatusFutureDateHeader:
        "Order status tracking will be available on {{servingDate}}",
      noUpcomingOrders: "No upcoming orders",
      noPastOrders: "No past orders",
      track: {
        confirmed: "Order confirmed.",
        preparing: "Our kitchen is preparing your order.",
        assigned: "A rider has been assigned to your order.",
        dispatched: "Your order has left our kitchen.",
        ready_for_pickup:
          "Your order is ready to be picked up at {{labelForPickup}}.",
      },
      deliveredBy: "Delivered by {{logisticsName}}",
      subtotal: "Subtotal:",
      trackingLink: "Track your order",
      deliveryFee: "Delivery fee:",
      freeDelivery: "FREE",
      tax: "GST:",
      serviceCharge: "Service charge:",
      taxIncluded: "GST (included):",
      surcharge: "Surcharge:",
      discount: "Discount:",
      donationAmount: "Donation",
      donationLabel: "{{donationLabel}} (Donation)",
      totalPayable: "Total payable:",
      paidWithCard: "{{cardBrandLabel}} ending with {{cardLast4}}",
      cardBrand: {
        mc: "Mastercard",
        visa: "Visa",
        amex: "AMEX",
        jcb: "JCB",
        diners: "Diners",
        cup: "UnionPay",
        amex_applepay: "AMEX",
        amex_googlepay: "AMEX",
        mc_applepay: "Mastercard",
        mc_googlepay: "Mastercard",
        visa_applepay: "Visa",
        visa_googlepay: "Visa",
      },
      logistics: {
        asl_lalamove: "Lalamove",
        asl_milk_run: "MilkRun",
        asl_pandago: "Pandago",
        asl_gogox: "GogoX",
        lalamove: "Lalamove",
        milk_run: "MilkRun",
        pandago: "Pandago",
        gogox: "GogoX",
      },
    },
    paymentMethods: {
      header: "Payment",
    },
    addresses: {
      header: "Addresses",
      subheader: "Select your default delivery address or add a new one.",
      selectAddressHeader: "Select delivery address",
      selectOutletHeader: "Served by",
      outletIsClosed: "Closed on selected date",
      notWithinServingArea: "Not within serving area",
      selectPickupPointHeader: "Pickup from an outlet",
      addAddressButton: "Add new address",
      removeAddressConfirmation:
        "Are you sure you want to remove this address?",
      distanceFromAddress: "{{distance}}km",
      nearestOutletServedBy: "Outlet: {{outletLabel}}",
    },
    referrals: {
      copyReferralLink: "Copy link",
      copied: "Copied!",
      successfullyApplied:
        "Referral was applied successfully! Your rewards can be found in the promo codes page.",
    },
    promoCodes: {
      header: "Promo codes",
      description:
        "These are all your promo codes you’ve collected to date. Make sure to use them before they expire!",
      activeHeader: "Active promo codes",
      pastHeader: "Past promo codes",
      noActive: "You have no active promo codes.",
      noPast: "You have no past promo codes.",
      viewActive: "View active promo codes",
      viewPast: "View past promo codes",
      promoQuantity: "x{{quantity}}",
      promoValidity: "Valid until {{formattedDate}}",
      viewPromoDetails: "View details",
      used: "Used",
      expired: "Expired",
      details: {
        termsHeader: "Terms and Conditions",
        validityPeriod: "Valid till {{formattedDate}}.",
        maxClaimsPerUser:
          "Limited to {{maxClaimsPerUser}} use(s) per customer.",
        useNow: "Use now",
        applied: "This promo code has been applied to your cart!",
        goToMenu: "Continue to menu",
      },
    },
    loyalty: {
      wallet: "Your wallet",
      worth: "(worth {{pointValue}}) ",
      goToWallet: "Go to wallet",
      cashback: "Cashback",
    },
  },
  addresses: {
    noAvailableOutlets: {
      header: "We're too far from you",
      copy: "Oh no, it seems that we are located too far away from you.",
      okay: "Okay",
    },
  },
  menu: {
    loading: "Loading menu",
    noMenu: "No available menu found.",
    sidebar: {
      header: "Our Menu",
    },
    deselected: {
      header: "No longer available",
      body: "{{label}} is not available at this location",
    },
    itemsRemoved: {
      proceed: "Proceed to order",
      modalHeader: "Your cart has been updated",
      modalContent:
        "The contents of your cart has been updated because you are now ordering from {{outletName}}.",
      removedLineItem: "{{item}} has been removed from your cart",
    },
    cart: {
      cartHeader: "Your cart",
      checkoutHeader: "Checkout",
      checkoutStages: {
        itemSummary: "Item Summary",
        details: "Details",
        payment: "Payment",
      },
      summary: "Cart Summary",
      empty: "Your cart is empty.",
      edit: "Edit item",
      editNotes: "Edit notes",
      showDetails: "Show details",
      removeConfirmation: {
        copy: "Remove {{itemName}} from cart?",
        remove: "Remove",
        cancel: "Cancel",
      },
      cartItem: {
        soldOut: "Sold out",
        someItemsAreUnavailable: "Only {{count}} left",
      },
      promotedProducts: {
        title: "Also goes well with...",
      },
      footer: {
        amountToFreeDelivery: "Add {{amount}} more for FREE delivery",
        freeDelivery: "Ready to rock and roll!",
        labels: {
          minimumOrderValue:
            "You need a minimum order of {{minimumOrderValue}}, add {{remainingValue}} more to checkout.",
          amountToNextDeliveryFee:
            "{{amount}} more to reduce delivery fee to {{nextDeliveryFee}}",
          checkout:
            "Go to checkout - <span id='go-to-checkout-amount'>{{amount}}</span>",
          deliveryFee: "Delivery fee",
          outletServedBy: "Outlet: {{labelForPickup}}",
          pickup: "Pickup",
          freeDelivery: "FREE",
          switchToDelivery: "Switch to delivery",
          switchToPickup: "Switch to pick up",
          subtotal: "Subtotal",
          usePoints: "Use {{pointsAmount}}",
          useCashback: "Use cashback",
          surcharge: "Surcharge",
          discount: "Discounts",
          serviceCharge: "Service charge",
          tax: "GST",
          taxIncluded: "GST (Included)",
          totalIncludingTax: "Total",
        },
      },
      verifyAlcoholAgeModal: {
        header: "Are you above 18 years old?",
        body: "Your cart contains alcoholic beverages listed below, please confirm that you are above 18 years of age to proceed with checkout.",
        yesLabel: "Yes, I am 18 years old or older, proceed to checkout",
        noLabel:
          "No, I am under 18 years old, proceed without any alcoholic beverages",
      },
      checkout: {
        wordcount: {
          giftMessage: "{{charactersLeft}} characters left",
        },
        labels: {
          serveOn: {
            delivery: "Deliver on",
            pickup: "Collect on",
            dine_in: "Collect on",
          },
          tableName: "Table: {{tableName}}",
          change: "Change",
          deliveryAddress: "Delivery address",
          pickUpFrom: "Pick up from",
          qrOutletLabel: "Ordering from",
          isGift: "Order as gift",
          isGiftDescription:
            "We will keep the price of this order a secret. You can leave a message for your recipient.",
          cutlery: "Cutlery",
          notes: {
            delivery: "Delivery instructions",
            pickup: "Pickup instructions",
            dine_in: "Dine-in instructions",
          },
          payment: {
            label: "Payment methods",
            payment_intent: {
              label: "Pay with credit card",
              body: null,
            },
            pay_now: {
              label: "Pay with ",
              body: "Kindly PayNow transfer to <span class='font-semibold'>{{uenOrMobile}}</span> and send a screenshot to <span class='font-semibold'>{{paymentContact}}</span> to help us process your order.",
            },
          },
          promoCode: "Promo code",
          redeemPoints: "Redeem {{customCurrencyPlural}}",
          redeemCashback: "Redeem cashback",
          pointBalance:
            "{{pointAmount}} {{customCurrencyPlural}} ({{pointValue}})",
          cashbackBalance: "{{pointValue}} cashback",
          checkout:
            "Place order - <span id='place-order-amount'>{{amount}}</span>",
          minimumOrderValue: "Minimum order of {{minimumOrderValue}}",
          contactName: "Name",
          contactNumber: "Contact number",
          email: "Email",
          recipientName: "Recipient name",
          recipientContactNumber: "Recipient contact number",
          giftMessage: "Gift message",
        },
        placeholders: {
          contactName: "How should we address you?",
          contactNumber: "Who should we call?",
          email: "Where do we send the order confirmation to?",
          notes: {
            delivery:
              "Is there anything we should take note of during delivery?",
            pickup: "Is there anything we should take note of during pickup?",
            dine_in: "Is there anything we should take note of?",
          },
          addressLine2: "Floor and unit no.*",
          promoCode: "Got a promo code?",
          recipientName: "How should we address the recipient?",
          recipientContactNumber: "What's the recipient's contact?",
          giftMessage: "What message would you like to give the recipient?",
          logInToUseCustomCurrency: "Log in to use {{customCurrencyName}}",
          logInToUseCashback: "Log in to use cashback",
        },
        validations: {
          contactName: "Contact name required",
          contactNumber: "Contact number required",
          invalidContactNumber: "Invalid contact number",
          email: "Invalid email format",
          addressLine2: "Unit number required",
          recipientName: "Recipient name required",
          giftMessage: "Gift message required",
        },
        promoCodeModal: {
          header: "Promo code",
          placeholder: "Enter a promo code",
          apply: "Apply",
        },
        stripeTotal: "Total",
        offlinePaymentsConfirmation:
          "You will be shown details on how to make payment. Checkout?",
        genericConfirmation: "Would you like to proceed with checkout?",
      },
    },
    menuSection: {
      empty: "There are no items in this section.",
    },
    menuItem: {
      addToCart: "Add",
      soldOut: "Sold out",
      preOrder: "Pre-order",
      notAvailable: "Not available",
      limitedStock: "Only {{quantityLeft}} available",
      menuNotAvailable: "Menu not available for selected date/time.",
    },
    productDetails: {
      labels: {
        ingredients: "Ingredients",
        allergens: "Allergen information",
        addToCart: "Add (<span class='product-price'>{{price}}</span>)",
        update: "Update (<span class='product-price'>{{price}}</span>)",
        preOrder: "Pre-order (<span class='product-price'>{{price}}</span>)",
        notAvailable: "Not available",
        addToCartWithoutPrice: "Add",
        updateWithoutPrice: "Update",
        notes: "Special instructions",
      },
      placeholders: {
        notes:
          "Let us know of any special requests, we will try our best to accommodate.",
      },
      remove: "Remove",
      removeConfirmation: "Confirm remove?",
      keep: "Keep",
      modifierGroup: {
        selection: {
          included: "Included",
          required:
            'Select $t(menu.productDetails.modifierGroup.selection.item, { "count": {{selectionRequiredMax}} }) ({{filled}}/{{selectionRequiredMax}})',
          optional:
            "Select between {{selectionRequiredMin}} and {{selectionRequiredMax}} items ({{filled}}/{{selectionRequiredMax}})",
          item: "{{count}} item",
          item_other: "{{count}} items",
        },
      },
      modifier: {
        selectedCount: "{{count}} ×",
        remove: "Remove",
      },
    },
    nutriGrade: {
      alt: "Nutri-Grade {{nutriGrade}}",
      sugar: "sugar",
      percent: "%",
      plus: "+",
    },
    preOrder: {
      header: "Order in advance",
      body: "This item has a lead time of {{time}}.",
      selectEarliestDate: "Order for {{date}}",
      selectAnotherDate: "Select another date or timeslot",
      time: {
        days: "1 day",
        days_other: "{{count}} days",
        hours: "1 hour",
        hours_other: "{{count}} hours",
        minutes: "1 minute",
        minutes_other: "{{count}} minutes",
      },
    },
    availability: {
      header: "Oops!",
      selectAnotherDate: "Select another date or timeslot",
    },
    checkoutModal: {
      success: {
        header: "Your order {{identifier}} is confirmed",
        headerTruncated: "Your call number",
        dine_in:
          "An order confirmation has been sent to your inbox. Your order will be ready for pick up on <span class='font-semibold'>{{ servingDate }}</span>, between <span class='font-semibold whitespace-nowrap'>{{ timeslotRangeLabel }}</span>.",
        pickup:
          "An order confirmation has been sent to your inbox. Your order will be ready for pick up on <span class='font-semibold'>{{ servingDate }}</span>, between <span class='font-semibold whitespace-nowrap'>{{ timeslotRangeLabel }}</span>.",
        asap_pickup:
          "An order confirmation has been sent to your inbox. Your order will be ready for pick up in <span class='font-semibold whitespace-nowrap'>{{ minutesToPickup }}</span>.",
        delivery:
          "An order confirmation has been sent to your inbox. Your order will arrive on <span class='font-semibold'>{{ servingDate }}</span>, between <span class='font-semibold whitespace-nowrap'>{{ timeslotRangeLabel }}</span>.",
        action: "Go back to main page",
        loyalty: {
          claimNow: "Claim now",
          viewWallet: "View Wallet",
          currencyEarned: "earned",
          cashbackEarned: "cashback earned",
        },
      },
      paymentDetails: {
        header: "Pending confirmation!",
        beforeQrCode:
          "We have received your order <span class='font-semibold'>{{identifier}}</span> but confirmation is pending.<br/><br/>Kindly PayNow transfer <span class='font-semibold'>{{price}}</span> to <span class='font-semibold'>{{uenOrMobile}}</span> and send a screenshot to <span class='font-semibold'>{{paymentMobileNumber}}</span> to help us process your order.",
        mobileNotes: {
          header: "For mobile users",
          body: "To transfer via PayNow, please screenshot the QR code and use it within any PayNow enabled banking application.",
        },
        afterQrCode:
          "You will receive an order confirmation email once we have confirmed your payment.",
        action: "Okay, I have made the transfer",
      },
      processing: {
        header: "Your order is being processed",
        description:
          "This can take anywhere from a few seconds to a few minutes.<br/>We will send you an email confirmation once we've completed processing your order.<br/>You can also stay on this page to wait for the process to be completed.",
      },
      thankYou: "Thank you",
    },
    pathname: "Menu",
  },
  stripe: {
    error: "An error occurred while processing your payment.",
  },
  adyen: {
    labels: {
      header: "Enter your payment details",
      card: "Credit/Debit card",
    },
    actions: {
      tryAgain: "Try again",
    },
    errors: {
      generic:
        "Please check that the card number, expiration, and security code is correct.",
    },
    payNowModal: {
      header: "Please pay within 3 minutes and return to this page",
      instruction1: "Take a screenshot of the QR code",
      instruction2: "Use your PayNow app and load the image",
      instruction3: "Return to this page after payment",
      instructionWarning:
        "Your order will not be confirmed if you do not pay <b>within 3 minutes</b> and <b>return to this page</b>.",
      acknowledge: "I understand",
    },
  },
  faq: {
    header: "Frequently asked questions",
    content: {
      question1: "How do I know whether my order is confirmed?",
      answer1: "You will find an order confirmation in your email inbox.",
      question2: "Can I place an order for a friend?",
      answer2:
        "Of course! Just select your friend's delivery address, add his/her favourite dishes to cart, and include the name and contact number in the special instructions.",
      question3: "How do I check the delivery status of my order?",
      answer3:
        "You can check the delivery status in your account — click on orders, then receipt. The delivery status is only available on the serving date.",
      question4: "What is in my meal?",
      answer4:
        "We want you to know exactly what goes into every dish we prepare, so you can view the full list of ingredients as well as major allergens by clicking on the picture of the dish. (We can’t list “love” though).",
      question5: "How long will my meal keep for?",
      answer5:
        "We prepare our meals fresh every day, so they are best consumed within 2-3 hours of delivery.",
      question6:
        "What happens if I am not around when the courier arrives with my meal?",
      answer6:
        "We will call or text you when we arrive, let us know if you would like us to leave the meal at your door.",
      question7: "Is my credit card information safe?",
      answer7:
        "Absolutely. We do not store any of this sensitive information on our servers. We use Stripe as our payment processor. Stripe has been audited by a PCI-certified auditor and is certified to PCI Service Provider Level 1. This is the most stringent level of certification available in the payments industry.",
    },
  },
  timeslotPicker: {
    header: "Select a date and timeslot",
    noTimeslotSelected: "Please select a timeslot",
    selectedTimeslotLabel: "{{cartDate}}, {{rangeLabel}}",
    fulfilmentTypeUnavailable:
      "Selected timeslot is not available for {{fulfilmentType}}",
    noTimeslots: "No timeslots available.",
    orderValueAdditionalLeadTimeWarning:
      "Orders above {{orderValue}} will need additional lead time of {{additionalLeadTime}}",
    closed: "Closed",
    typeLabel: {
      asap: "As soon as possible",
      breakfast: "Breakfast",
      brunch: "Brunch",
      breakfast_and_lunch: "Breakfast & Lunch",
      lunch: "Lunch",
      off_peak: "Off-Peak",
      offPeak: "Off-Peak",
      tea_time: "Tea-time",
      happyHour: "Happy Hour",
      dinner: "Dinner",
      supper: "Supper",
      available_timeslots: "Available timeslots",
      preorders: "Pre-orders",
    },
  },
  addressPicker: {
    header: "Where should we deliver to?",
    placeholder: "Enter a street name or postal code",
    noResults: "No results",
    okText: "Confirm address",
    confirm: "Confirm",
    cancel: "Cancel",
    error: {
      noPostalCode:
        "We can't seem to find this address. Try searching for postal code instead?",
    },
    outletIsClosedToday:
      "Your selected outlet is closed today. Try a different outlet or date?",
    outletIsClosed:
      "Your selected outlet is closed on chosen date. Try a different outlet or date?",
  },
  outletSelector: {
    header: "Where do you want to pick up from?",
  },
  fulfilmentTypes: {
    delivery: "Delivery",
    pickup: "Pickup",
    dine_in: "Dine-in",
  },
  multiBrand: {
    ourConcepts: "Our concepts",
  },
  login: {
    header: "Welcome back",
    subheader: "Log in to your account",
    description:
      "Log in for faster checkouts, order tracking and exclusive deals for you and your friends.",
    descriptionBeforeSignupLink: "No account yet? ",
    signupAction: "Sign up here",
    referralHeader:
      "Welcome back, <br class='hidden sm:block' />{{referrerName}} told us you'd be coming",
    referralSubheader1: "Log in and get {{rewardValue}} off your first order.",
    referralSubheader2: "Don't have an account? ",
    referralSubheader3: "Sign up",
    emailLabel: "Email address",
    emailPlaceholder: "Enter your email",
    facebookLoginButton: "Continue with Facebook",
    googleLoginButton: "Continue with Google",
    otpButton: "Log in",
    useEmailInput: "Continue with email",
    useMobileNumberInput: "Continue with phone",
    whatIsAtlas: "What is Atlas?",
    error: {
      emailNotFound: "Email is not registered yet.",
      mobileNumberNotFound: "Mobile number is not registered yet.",
      EMAIL_TAKEN:
        "<strong>{{email}}</strong> has an existing account registered.",
      MOBILE_NUMBER_TAKEN:
        "<strong>{{mobileNumber}}</strong> has an existing account registered.",
    },
  },
  signup: {
    header: "Sign up for a {{storefrontLabel}} account",
    header_vowel: "Sign up for an {{storefrontLabel}} account",
    description:
      "Get faster checkouts, order tracking and exclusive deals for you and your friends with your account.",
    descriptionBeforeLoginLink: "Already have an account? ",
    loginAction: "Log in here",
    signupAndCheckout: "Sign up and checkout",
    skipSignup: "Skip to checkout as a guest",
    referralHeader:
      "Hey there, <br class='hidden sm:block' />{{referrerName}} told us you'd be coming",
    referralSubheader1: "Sign up and get {{rewardValue}} off your first order.",
    referralSubheader2: "Already have an account? ",
    referralSubheader3: "Log in.",
    titleLabel: "Title",
    titlePlaceholder: "How should we address you?",
    nameLabel: "Name",
    namePlaceholder: "What is your full name?",
    mobilePlaceholder: "Contact number",
    dobLabel: "Date of birth",
    dobPlaceholder: "When is your birthday?",
    marketingConsentLabel:
      "I consent to receiving exclusive discounts, seasonal specials, and latest updates via email.",
    privacyPolicyTitle: "privacy policy",
    termsOfServiceTitle: "terms of service",
    privacyJoinCopy: " and ",
    privacyPolicyLineEnding: "as well as ",
    privacyAgreement:
      "By continuing, you agree to the {{ partnerPolicy }} Atlas's <a class='font-bold text-link hover:text-link' target='_blank' href='https://atlaskitchen.notion.site/Atlas-Online-Terms-of-Use-dedd931f781f4d609ce784461f245690'>terms of service</a> and <a class='font-bold text-link hover:text-link' target='_blank' href='https://atlaskitchen.notion.site/Atlas-Online-Privacy-Policy-c256dd2a5fa64f1eb903d03b04f6ea3d'>privacy policy</a>.",
    signupButton: "Sign up for an account",
    facebookSignupButton: "Sign up with Facebook",
    googleSignupButton: "Sign up with Google",
    otpButton: "Sign up",
    signupReferralCodePlaceHhlder: "Signup referral code",
    whatIsAtlas: "What is Atlas?",
    error: {
      titleRequired: "Title is required",
      nameRequired: "Name is required",
      emailRequired: "Email is required",
      invalidEmail: "Invalid email address",
      invalidPassword:
        "Password must contain a minimum of eight characters with a combination of numbers and letters",
      passwordsNotMatch: "Passwords do not match",
      mobileNumberRequired: "Mobile number is required",
      invalidMobileNumber: "This is not a valid mobile number",
    },
    options: {
      title: {
        mr: "Mr",
        mrs: "Mrs",
        miss: "Miss",
        mx: "Mx",
      },
    },
  },
  otpModal: {
    otpChannels: {
      sms: "SMS",
      email: "email",
    },
    loginTitle: "Log in with 6-digit code",
    loginSubTitle:
      "Enter the code sent to your {{deliveryMode}} to log in to your {{channelLabel}} account.",
    signUpTitle: "Sign up with 6-digit code",
    signUpSubTitle:
      "Enter the code sent to {{deliveryMode}} to sign up to your {{channelLabel}} account.",
    verifyTitle: "Verify with 6-digit code",
    verifySubTitle:
      "Enter the code sent to {{deliveryMode}} to verify your phone number.",
    codeSentToEmail: "Code sent to {{email}}",
    codeSentToSms: "Code sent to phone {{phone}}",
    codeResentToEmail: "Code resent to {{email}}",
    codeResentToSms: "Code resent to phone {{phone}}",
    sendToEmail: "send to {{email}}",
    sendToSms: "send to phone {{phone}}",
    resendToEmail: "Resend",
    resendToSms: "Resend",
    or: " or ",
    delayMessage: "Please wait 30 seconds to resend again.",
    successMessage:
      "Verification successful. Redirecting you to the next step.",
  },
  footer: {
    sitemap: {
      home: "Home",
      menu: "Menu",
      customerReviews: "Customer Reviews",
      faqs: "FAQs",
    },
    socialLinks: {
      label: "Social Media",
      facebook: "Facebook",
      instagram: "Instagram",
      twitter: "Twitter",
    },
    legal: {
      label: "Legal",
      cookiesPolicy: "Cookies Policy",
      documentation: "Documentation",
      privacyPolicy: "Privacy Policy",
    },
    contactInfo: {
      label: "Contact Us",
    },
  },
  pages: {
    contactForm: {
      labels: {
        name: "Name",
        email: "Email",
        contactNumber: "Contact number",
        subject: "Subject",
        message: "Message",
      },
      submitButtonTitle: "Submit",
      submitSuccessMessage: "Form submitted successfully.",
    },
    nutritionCalculator: {
      resultsHeader: "Results",
      macrosHeader: "Nutrition Facts",
      selectedHeader: "Selected",
      resetButtonTitle: "Reset",
      macros: {
        label: {
          calories: "Calories",
          protein: "Protein",
          carbs: "Carbs",
          fat: "Fat",
        },
        suffix: {
          calories: "",
          protein: "g",
          carbs: "g",
          fat: "g",
        },
      },
    },
  },
  reloadPrompt: {
    optional: {
      title: "New update available",
      subTitle: "To ensure the best experience, please refresh this page.",
      okText: "Reload",
      cancelText: "Dismiss",
    },
    mandatory: {
      title: "Update required",
      subTitle:
        "To ensure that the application runs smoothly, please update now.",
      okText: "Reload",
    },
  },
  promotedProductsModal: {
    title: "Would you like to complement your meal with these items?",
    cancelButton: "No, thank you",
    okButton: "View cart - {{amount}}",
  },
};
